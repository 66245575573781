import React from 'react';
import PropTypes from 'prop-types';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';

class RangeCalendar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      availableDates: [],
    };
    this.setupCalendarValues = this.setupCalendarValues.bind(this);
    this.changeDate = this.changeDate.bind(this);
  }

  componentDidMount() {
    this.setupCalendarValues();
  }

  setupCalendarValues() {
    const {
      availableExpirationDates,
      startDate,
      curDate,
      discreteHandleSelectDate,
    } = this.props;
    const availableDates = availableExpirationDates.filter((dateC) => !(dateC < startDate));
    this.setState({ availableDates });
    discreteHandleSelectDate((availableDates.includes(curDate)) ? curDate : availableDates[0]);
  }

  changeDate(value) {
    const { handleSelectDate } = this.props;
    handleSelectDate(value);
  }

  render() {
    const {
      startDate,
      curDate,
    } = this.props;
    const { availableDates } = this.state;
    return (
      <Calendar
        value={curDate}
        onChange={this.changeDate}
        tileDisabled={({ date, view }) => (view === 'month') // Block day tiles only
          && availableDates.every((availableDate) => (
            date.getFullYear() !== availableDate.getFullYear()
            || date.getMonth() !== availableDate.getMonth()
            || date.getDate() !== availableDate.getDate()
          ))}
        minDate={startDate}
        maxDate={availableDates[availableDates.length - 1]}
      />
    );
  }
}

RangeCalendar.propTypes = {
  availableExpirationDates: PropTypes.arrayOf(PropTypes.instanceOf(Date)).isRequired,
  curDate: PropTypes.instanceOf(Date).isRequired,
  startDate: PropTypes.instanceOf(Date).isRequired,
  handleSelectDate: PropTypes.func.isRequired,
  discreteHandleSelectDate: PropTypes.func.isRequired,
};

export default RangeCalendar;
