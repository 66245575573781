import React from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from 'react-router-dom';
import ReactNotification from 'react-notifications-component';
import Tickerpage from './components/Tickerpage/Tickerpage';
import TradeIronCondor from './components/Tickerpage/IronCondor/TradeIronCondor/TradeIronCondor';
import Tradestock from './components/Tradestock/Tradestock';
import Homepage from './components/Homepage/Homepage';
import Profilepage from './components/Profilepage/Profilepage';
import LoginPage from './components/Login/LoginPage';
import ViewOrders from './components/ViewOrders/ViewOrders';
import 'bootstrap/dist/css/bootstrap.css';
import './App.css';
import 'react-notifications-component/dist/theme.css';
import PrivateRoute from './components/PrivateRoute/PrivateRoute';
import Unauthorized from './components/Auth/Unauthorized';
// import IronCondor2 from './components/Tickerpage/IronCondor/IronCondor2';

function App() {
  return (
    <Router>
      <div>
        <Switch>
          <Redirect from="/condor-for-the-common-man" to="/" />
          <Route exact path="/" component={Homepage} />
          <Route exact path="/ticker" component={Tickerpage} />
          <Route exact path="/tradeironcondor" component={TradeIronCondor} />
          <Route exact path="/tradestock" component={Tradestock} />
          <Route exact path="/orders" component={ViewOrders} />
          <Route exact path="/login">
            <LoginPage />
          </Route>
          <PrivateRoute exact path="/profile" component={Profilepage} />
          <Route path="/unauthorized" component={Unauthorized} />
          <Route path="*" component={() => 'Error 404: NOT FOUND'} />
        </Switch>
        <ReactNotification />
      </div>
    </Router>
  );
}

export default App;
