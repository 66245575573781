/* eslint-disable camelcase */
/* eslint-disable react/no-did-update-set-state */
import React from 'react';
import { withRouter } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.css';

import * as am4core from '@amcharts/amcharts4/core';
import * as am4charts from '@amcharts/amcharts4/charts';
import am4themes_animated from '@amcharts/amcharts4/themes/animated';
import axios from 'axios';
import PropTypes from 'prop-types';
import config from '../../config.json';

const serverAddress = config.SERVER_ADDR;
const stockchartInfoEndPoint = 'stockinfo';
const dateFormat = 'yyyy-MM-dd HH:mm:ss';

am4core.useTheme(am4themes_animated);

class Stockchart extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      ticker: 'AAPL',
      range: 'today',
      start: '1900-01-01',
      end: '2099-12-31',
    };

    this.handleSubmit = this.handleSubmit.bind(this);
    this.loadStockChart = this.loadStockChart.bind(this);
    this.onChangeValue = this.onChangeValue.bind(this);
    this.onChangeStart = this.onChangeStart.bind(this);
    this.onChangeEnd = this.onChangeEnd.bind(this);
  }

  componentDidMount() {
    const { ticker } = this.props;
    this.setState({ ticker }, () => {
      this.handleSubmit();
    });
  }

  componentDidUpdate(prevProps) {
    const { ticker } = this.props;
    if (prevProps.ticker !== ticker) {
      this.setState({ ticker }, () => {
        this.handleSubmit();
      });
    }
  }

  componentWillUnmount() {
    if (this.chart) {
      this.chart.dispose();
    }
  }

  handleSubmit() {
    const { ticker } = this.state;
    const { range } = this.state;
    const { start } = this.state;
    const { end } = this.state;
    const url = `${serverAddress}/api/${stockchartInfoEndPoint}`;
    if (ticker !== '') {
      if (range !== 'custom') {
        axios.get(url, {
          params: { ticker_symbol: ticker, range },
        }, { crossDomain: true }).then((res) => {
          const chartData = JSON.parse(res.data);
          // eslint-disable-next-line
          this.loadStockChart(chartData);
        }).catch(() => {
        });
      } else {
        axios.get(url, {
          params: {
            ticker_symbol: ticker, range, start, end,
          },
        }, { crossDomain: true }).then((res) => {
          const chartData = JSON.parse(res.data);
          this.loadStockChart(chartData);
        }).catch(() => {
        });
      }
    }
  }

  onChangeValue(event) {
    this.setState({
      range: event.target.value,
    }, () => {
      this.start.value = '';
      this.end.value = '';
      this.handleSubmit();
    });
  }

  onChangeStart(event) {
    this.setState({
      start: event.target.value,
    }, () => {
      this.handleSubmit();
    });
  }

  onChangeEnd(event) {
    this.setState({
      end: event.target.value,
    }, () => {
      this.handleSubmit();
    });
  }

  loadStockChart(chartData) {
    let i;
    let minValue = 0;
    let maxValue = 0;
    if (this.chart) {
      this.chart.dispose();
    }
    const chart = am4core.create('chartdiv', am4charts.XYChart);
    chart.dateFormatter.inputDateFormat = dateFormat;

    // chart.categoryAxesSettings.equalSpacing = true;

    chart.paddingRight = 20;
    const values = [];
    for (i = 0; i < chartData.length; i += 1) {
      values.push(chartData[i].value);
    }
    // eslint-disable-next-line
    minValue = Math.min.apply(Math, values);
    // eslint-disable-next-line
    maxValue = Math.max.apply(Math, values);

    const gradient = new am4core.LinearGradient();
    const first_data_point = chartData[0].value;
    const last_data_point = chartData[chartData.length - 1].value;
    if (last_data_point >= first_data_point) {
      chart.colors.list = [
        am4core.color('#00bf46'),
      ];
      gradient.addColor(am4core.color('#00bf46'));
      gradient.addColor(am4core.color('#FFFFFF'));
    } else {
      chart.colors.list = [
        am4core.color('#bf0000'),
      ];
      gradient.addColor(am4core.color('#bf0000'));
      gradient.addColor(am4core.color('#FFFFFF'));
    }

    chart.data = chartData;

    const dateAxis = chart.xAxes.push(new am4charts.DateAxis());
    dateAxis.renderer.grid.template.location = 0;
    dateAxis.skipEmptyPeriods = true;
    dateAxis.tooltip.disabled = true;
    dateAxis.startLocation = 0.0;
    dateAxis.startLocation = 0.5;
    dateAxis.dateFormats.setKey('hour', 'hh:mm a');

    const valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
    valueAxis.tooltip.disabled = true;
    valueAxis.renderer.minWidth = 35;
    valueAxis.min = minValue;
    valueAxis.max = maxValue;

    const series = chart.series.push(new am4charts.LineSeries());
    series.showOnInit = false;
    series.dataFields.dateX = 'date';
    series.dataFields.valueY = 'value';
    series.strokeWidth = 2;
    series.fillOpacity = 0.2;

    // dateAxis.dateFormatter = new am4core.DateFormatter();
    // dateAxis.dateFormatter.dateFormat = 'yyyy-MM-dd hh-mm';
    // gradient.rotation = 90;
    // series.fill = gradient;

    series.tooltipText = '{valueY.value}';
    // series.columns.template.tooltipY = 0;
    // series.tooltip.fill = am4core.color('#FFFFFF');
    chart.cursor = new am4charts.XYCursor();
    chart.cursor.lineY.disabled = true;
    chart.cursor.lineX.strokeDasharray = '';

    const scrollbarX = new am4charts.XYChartScrollbar();
    scrollbarX.series.push(series);
    chart.scrollbarX = scrollbarX;
    chart.scrollbarX.parent = chart.bottomAxesContainer;

    this.chart = chart;
  }

  render() {
    const { ticker } = this.state;
    return (
      <div className="chart-container">
        <div className="ticker-info">
          { ticker }
        </div>
        <div className="nav-bar">
          <div className="left options-bar">
            <button type="submit" name="range" value="today" data-testid="day" onClick={this.onChangeValue}>1D</button>
            <button type="submit" name="range" value="week" data-testid="week" onClick={this.onChangeValue}>1W</button>
            <button type="submit" name="range" value="month" data-testid="month" onClick={this.onChangeValue}>1M</button>
            <button type="submit" name="range" value="year" data-testid="year" onClick={this.onChangeValue}>1Y</button>
            <button type="submit" name="range" value="custom" data-testid="custom" onClick={this.onChangeValue}>Custom</button>
          </div>
          <div className="left custom-bar">
            <label htmlFor="start">
              Start date:
              <input type="date" id="start" name="custom-range-start" min="1900-01-01" max="2099-12-31" onChange={this.onChangeStart} ref={(c) => { this.start = c; }} data-testid="start" />
            </label>
            <label htmlFor="end">
              End date:
              <input type="date" id="end" name="custom-range-end" min="1900-01-01" max="2099-12-31" onChange={this.onChangeEnd} ref={(c) => { this.end = c; }} data-testid="end" />
            </label>
          </div>
        </div>
        <div id="chartdiv" style={{ width: '90%', height: '500px' }} />
      </div>
    );
  }
}

Stockchart.propTypes = {
  ticker: PropTypes.string.isRequired,
};

export default withRouter(Stockchart);
