/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import { checkIsAuthenticated } from '../../services/auth';

const PrivateRoute = ({ component: Component, ...otherProps }) => (
  <Route
    {...otherProps}
    render={(props) => (
      checkIsAuthenticated() ? <Component {...props} /> : <Redirect to={otherProps.redirectTo ? otherProps.redirectTo : '/unauthorized'} />
    )}
  />
);

PrivateRoute.propTypes = {
  component: PropTypes.elementType.isRequired,
};

export default PrivateRoute;
