/* eslint-disable no-param-reassign */
/* eslint-disable react/no-did-update-set-state */
import React from 'react';
import { Scatter } from 'react-chartjs-2';
import * as ChartAnnotation from 'chartjs-plugin-annotation';
import * as ChartDragData from 'chartjs-plugin-dragdata';
import * as ChartZoom from 'chartjs-plugin-zoom';
import { store } from 'react-notifications-component';
import PropTypes from 'prop-types';

class IronCondor extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      ticks: null,
      chartReference: null,
      chartData: null,
      options: null,
      updateCallback: props.updateCallback,
    };
    this.plotBreakEven = true;
    this.isDraggingPoint = false;
    this.buildChart = this.buildChart.bind(this);
    this.disableBreakevenLine = this.disableBreakevenLine.bind(this);
  }

  componentDidMount() {
    this.setState({ chartReference: React.createRef() });
    this.buildChart(false);
  }

  componentDidUpdate(previousProps, previousState) {
    const {
      chartReference, ticks, chartData, options,
    } = this.state;
    if (JSON.stringify(previousProps) !== JSON.stringify(this.props)) {
      // data is updated
      this.setState({
        ticks: null,
        chartData: null,
        options: null,
      });
    } else if (!chartData && !options && !ticks) {
      // just reset the data
      this.setState({ chartReference: React.createRef() });
      this.buildChart(false);
    } else if (ticks == null && chartReference.current != null) {
      // chart with original data just plotted
      const maxY = Math.max(...this.yVals);
      const minY = Math.min(...this.yVals);
      const bottom = chartReference.current.chartInstance.scales['y-axis-1'].getPixelForValue(minY);
      const top = chartReference.current.chartInstance.scales['y-axis-1'].getPixelForValue(maxY);
      const zero = chartReference.current.chartInstance.scales['y-axis-1'].getPixelForValue(0);
      this.pos = { bottom, top, zero };
      this.setState({
        ticks: chartReference.current.chartInstance.boxes[2].ticksAsNumbers,
      });
    } else if (previousState.ticks !== ticks && ticks) {
      // now plot the actual chart
      this.buildChart(true);
    }
  }

  disableBreakevenLine() {
    const { chartReference } = this.state;
    const { currStockPrice } = this.props;
    chartReference.current.chartInstance.options.annotation = {
      drawTime: 'afterDatasetsDraw',
      annotations: [{
        type: 'line',
        mode: 'horizontal',
        scaleID: 'y-axis-1',
        value: 0,
        borderColor: 'black',
        borderWidth: 2,
      },
      {
        type: 'line',
        mode: 'vertical',
        scaleID: 'x-axis-1',
        value: currStockPrice,
        borderColor: 'blue',
        label: {
          position: 'center',
          content: `Current: $${currStockPrice}`,
          yAdjust: 10,
          enabled: true,
        },
      }],
    };
  }

  buildChart(withSentinel) {
    const chart = this;
    const {
      credits,
      multiplier,
      strikePrices,
      shortIronCondor,
      currStockPrice,
      strikes,
    } = this.props;
    const { ticks, updateCallback } = this.state;
    const netCredit = -credits[0] + credits[1] + credits[2] - credits[3];
    let yVals = [0, 0, 0, 0];
    yVals[0] = ((strikePrices[0] - strikePrices[1]) + netCredit) * multiplier;
    yVals[1] = netCredit * multiplier;
    yVals[2] = netCredit * multiplier;
    yVals[3] = ((strikePrices[2] - strikePrices[3]) + netCredit) * multiplier;

    const breakEvenPoint = [0, 0];
    breakEvenPoint[0] = strikePrices[0]
      + (-yVals[0] * (strikePrices[1] - strikePrices[0])) / (-yVals[0] + yVals[1]);
    breakEvenPoint[1] = strikePrices[3]
      - (-yVals[3] * (strikePrices[3] - strikePrices[2])) / (yVals[2] - yVals[3]);

    let names = ['Long Put', 'Short Put', 'Short Call', 'Long Call'];
    let strikeCredits = [`-$${credits[0]}`, `+$${credits[1]}`, `+$${credits[2]}`, `-$${credits[3]}`];
    let annotationLabelPos = 'bottom';
    if (!shortIronCondor) {
      yVals = yVals.map((v) => -v);
      names = ['Short Put', 'Long Put', 'Long Call', 'Short Call'];
      strikeCredits = [`+$${credits[0]}`, `-$${credits[1]}`, `-$${credits[2]}`, `+$${credits[3]}`];
      annotationLabelPos = 'top';
    }
    strikeCredits = strikeCredits.map((text) => `Credit: ${text}`);

    this.yVals = yVals;

    const maxY = Math.max(...yVals);
    const minY = Math.min(...yVals);
    const yRange = maxY - minY;

    const maxX = strikes[strikes.length - 1];
    const minX = strikes[0];

    const data = [];
    let i;
    for (i = 0; i < 4; i += 1) {
      data.push({
        x: strikePrices[i],
        y: yVals[i],
        name: names[i],
        credit: strikeCredits[i],
      });
    }

    let ticksConfig = {};
    const xBound = [null, null];
    if (withSentinel) {
      const stepSize = ticks[1] - ticks[0];
      const startSentinelX = ticks[0] - stepSize * 2;
      const ticksLength = ticks.length;
      const endSentinelX = ticks[ticksLength - 1] + stepSize * 2;

      xBound[0] = Math.floor((Math.max(0, minX) - stepSize * 2) / stepSize) * stepSize;
      xBound[1] = Math.ceil((maxX + stepSize * 2) / stepSize) * stepSize;

      data.unshift({
        x: xBound[0] - (ticks[0] - xBound[0]) * 2,
        y: yVals[0],
      });
      data.push({
        x: xBound[1] + (xBound[1] - ticks[ticksLength - 1]) * 2,
        y: yVals[3],
      });
      ticksConfig = {
        min: startSentinelX,
        max: endSentinelX,
        callback: (value) => {
          if (value < 0) {
            return `-$${(Math.round(-value * 100) / 100).toFixed(2)}`;
          }
          return `$${(Math.round(value * 100) / 100).toFixed(2)}`;
        },
      };
    }
    const breakEvenRight = breakEvenPoint[1] < strikePrices[3] ? {
      type: 'line',
      mode: 'vertical',
      scaleID: 'x-axis-1',
      value: breakEvenPoint[1],
      borderColor: 'green',
      borderDash: [5, 5],
      label: {
        position: annotationLabelPos,
        content: `Breakeven Point: $${breakEvenPoint[1]}`,
        yAdjust: 10,
        enabled: true,
      },
    } : {};

    const breakEvenLeft = breakEvenPoint[0] > strikePrices[0] ? {
      type: 'line',
      mode: 'vertical',
      scaleID: 'x-axis-1',
      value: breakEvenPoint[0],
      borderColor: 'green',
      borderDash: [5, 5],
      label: {
        position: annotationLabelPos,
        content: `Breakeven Point: $${breakEvenPoint[0]}`,
        yAdjust: 10,
        enabled: true,
      },
    } : {};

    const options = {
      showXLabels: 30,
      dragData: true,
      dragX: true,
      dragDataRound: 0,
      dragOptions: {
        showTooltip: true,
      },
      onDragStart() {
        chart.disableBreakevenLine();
        chart.isDraggingPoint = true;
      },
      onDrag(e, datasetIndex, index, value) {
        value.y = yVals[index - 1];
        let nearest = null;
        const curr = value.x;
        for (i = 0; i < strikes.length; i += 1) {
          const val = strikes[i];
          if (val >= data[index + 1].x) {
            break;
          }
          if (val > data[index - 1].x) {
            if (nearest == null || Math.abs(curr - val) <= Math.abs(curr - nearest)) {
              nearest = val;
            } else {
              break;
            }
          }
        }
        value.x = nearest;
        value.credit = 'Release to fetch updated credit';
      },
      onDragEnd(e, datasetIndex, index, value) {
        chart.isDraggingPoint = false;
        updateCallback(index - 1, value.x);
        store.addNotification({
          title: 'Warning',
          message: `Strike Price Update For ${value.name} Received. Requested Price is ${value.x}`,
          type: 'warning',
          insert: 'bottom',
          container: 'top-right',
          dismiss: {
            duration: 3000,
            onScreen: true,
          },
        });
      },
      scales: {
        yAxes: [{
          scaleLabel: {
            display: true,
            labelString: 'Profit/Loss',
          },
          ticks: {
            autoSkip: true,
            beginAtZero: true,
            suggestedMin: minY - 0.1 * yRange,
            suggestedMax: maxY + 0.1 * yRange,
            callback: (value) => {
              if (value < 0) {
                return `-$${Math.round(-value)}`;
              }
              return `$${Math.round(value)}`;
            },
          },
        }],
        xAxes: [{
          type: 'linear',
          scaleLabel: {
            display: true,
            labelString: 'Stock Prices',
          },
          ticks: ticksConfig,
        }],
      },
      title: {
        display: true,
        text: 'Iron Condor Profit/Loss Graph',
      },
      legend: {
        display: false,
      },
      annotation: {
        drawTime: 'afterDatasetsDraw',
        annotations: [{
          type: 'line',
          mode: 'horizontal',
          scaleID: 'y-axis-1',
          value: 0,
          borderColor: 'black',
          borderWidth: 2,
        },
        {
          type: 'line',
          mode: 'vertical',
          scaleID: 'x-axis-1',
          value: currStockPrice,
          borderColor: 'blue',
          label: {
            position: 'center',
            content: `Current: $${currStockPrice}`,
            yAdjust: 10,
            enabled: true,
          },
        },
        breakEvenLeft,
        breakEvenRight],
      },
      animation: {
        duration: 400,
        easing: 'easeOutBack',
      },
      tooltips: {
        enabled: true,
        intersect: false,
        callbacks: {
          title: (tooltipItem, tooltipData) => {
            const idx = tooltipItem[0].index;
            return `${tooltipData.datasets[0].data[idx].name} Strike Price: $${tooltipItem[0].label}`;
          },
          afterTitle: (tooltipItem, tooltipData) => {
            const idx = tooltipItem[0].index;
            return tooltipData.datasets[0].data[idx].credit;
          },
          label: (tooltipItem) => {
            const { zero, top, bottom } = this.pos;
            const idx = tooltipItem.index;
            const profitLoss = tooltipItem.yLabel;
            const profitLossNumStr = (Math.round(Math.abs(profitLoss) * 100) / 100).toFixed(2);
            const profiltLossStr = profitLoss >= 0 ? 'Profit' : 'Loss';
            let maxMinStr = 'Maximum';
            if ((idx === 2 || idx === 3) && top > zero) {
              maxMinStr = 'Minimum';
            } else if ((idx === 1 || idx === 4) && bottom < zero) {
              maxMinStr = 'Minimum';
            }

            return `${maxMinStr} ${profiltLossStr}: $${profitLossNumStr}`;
          },
        },
      },
      plugins: {
        zoom: {
          pan: {
            enabled: true,
            mode() {
              if (!chart.isDraggingPoint) {
                return 'x';
              }
              return '';
            },
            overScaleMode: 'xy',
            rangeMin: {
              x: xBound[0],
              y: null,
            },
            rangeMax: {
              x: xBound[1],
              y: null,
            },
            speed: 20,
            threshold: 10,
          },
          zoom: {
            enabled: true,
            mode: 'x',
            rangeMin: {
              x: xBound[0],
              y: null,
            },
            rangeMax: {
              x: xBound[1],
              y: null,
            },
            speed: 0.1,
            threshold: 2,
            sensitivity: 3,
          },
        },
      },
    };

    const dataFunc = (canvas) => {
      if (this.pos && withSentinel) {
        const { zero, top, bottom } = this.pos;
        const ctx = canvas.getContext('2d');
        this.gradient = ctx.createLinearGradient(0, top, 0, bottom);
        const ratio = Math.min((zero - top) / (bottom - top), 1);
        if (top > zero) {
          // maximum profit is below 0 (we are alwaysing losing money)
          this.gradient.addColorStop(0, 'rgba(256, 0, 0, 0.1)');
          this.gradient.addColorStop(1, 'rgba(256, 0, 0, 1)');
        } else if (bottom < zero) {
          // maximum loss is above 0 (we are always making money)
          this.gradient.addColorStop(0, 'rgba(0, 256, 0, 1)');
          this.gradient.addColorStop(1, 'rgba(0, 256, 0, 0.1)');
        } else {
          this.gradient.addColorStop(0, 'rgba(0, 256, 0, 1)');
          this.gradient.addColorStop(ratio, 'rgba(0, 256, 0, 0.1)');
          this.gradient.addColorStop(ratio, 'rgba(256, 0, 0, 0.1)');
          this.gradient.addColorStop(1, 'rgba(256, 0, 0, 1)');
        }
      }
      const chartData = {
        datasets: [
          {
            backgroundColor: this.gradient,
            lineTension: 0,
            borderColor: 'rgba(0,0,0,1)',
            borderWidth: 2,
            spanGaps: false,
            showLine: true,
            pointRadius: 5,
            pointBorderWidth: 2,
            pointHoverRadius: 10,
            pointHoverBorderWidth: 4,
            data,
          },
        ],
      };
      return chartData;
    };

    this.setState({ options, chartData: dataFunc });
  }

  render() {
    const { chartReference, chartData, options } = this.state;
    if (chartData && options) {
      return (
        <div id="iron-condor" style={{ width: '1000px', height: '500px' }}>
          <Scatter
            ref={chartReference}
            data={chartData}
            options={options}
            plugins={[ChartAnnotation, ChartDragData, ChartZoom]}
          />
        </div>
      );
    }
    return (
      <div id="iron-condor" style={{ width: '500px', height: '250px' }} />
    );
  }
}

IronCondor.propTypes = {
  credits: PropTypes.arrayOf(PropTypes.number).isRequired,
  multiplier: PropTypes.number.isRequired,
  strikePrices: PropTypes.arrayOf(PropTypes.number).isRequired,
  shortIronCondor: PropTypes.bool.isRequired,
  currStockPrice: PropTypes.number.isRequired,
  updateCallback: PropTypes.func.isRequired,
  strikes: PropTypes.arrayOf(PropTypes.number).isRequired,
};

export default IronCondor;
