/* eslint-disable react/no-did-update-set-state */
import React from 'react';
import axios from 'axios';
// import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { store } from 'react-notifications-component';
import { Button } from 'react-bootstrap';
import { MDBDataTable } from 'mdbreact';
import Cookies from 'js-cookie';
import Header from '../Header/Header';
import config from '../../config.json';

const serverAddress = config.SERVER_ADDR;
// const tickerInfoEndPoint = '';

class ViewOrders extends React.Component {
  constructor(props) {
    super(props);
    this.getOrders = this.getOrders.bind(this);
    this.state = {
      data: null,
      accountId: '',
      box: {
        textAlign: 'center',
        margin: '100px',
        verticalAlign: 'middle',
        backgroundColor: 'white',
      },
    };
    axios.request({
      method: 'GET',
      url: `${serverAddress}/api/profile/?token=${Cookies.get('token')}&endpoint=${Cookies.get('endpoint')}`,
    }).then((res) => {
      this.setState({
        accountId: res.data.account[0].account_number,
      });
    }).catch((error) => {
      console.log(error);
    });
  }

  componentDidMount() {
  }

  getOrders() {
    const token = Cookies.get('token');
    const endpoint = Cookies.get('endpoint');
    const { accountId } = this.state;
    axios({
      method: 'GET',
      url: `${serverAddress}/api/orders/`,
      params: {
        token,
        endpoint,
        account_id: accountId,
      },
    }).then((res) => {
      this.setState({
        data: JSON.parse(res.data),
      });
      store.addNotification({
        title: 'Yes',
        message: 'Your current orders have been refreshed!',
        type: 'success',
        insert: 'top',
        container: 'top-right',
        dismiss: {
          duration: 8000,
          onScreen: true,
        },
      });
    }).catch((error) => {
      // request failed
      console.log(error);
      store.addNotification({
        title: 'Error',
        message: 'Request failed. It is most likely that there is something wrong on our end. Please try again later.',
        type: 'danger',
        insert: 'bottom',
        container: 'top-right',
        dismiss: {
          duration: 8000,
          onScreen: true,
        },
      });
    });
  }

  onChange = (event) => this.setState({ [event.target.name]: event.target.value });

  cancelOrders(orderId) {
    const token = Cookies.get('token');
    const endpoint = Cookies.get('endpoint');
    const { accountId } = this.state;
    axios({
      method: 'DELETE',
      url: `${serverAddress}/api/cancel/`,
      params: {
        token,
        endpoint,
        account_id: accountId,
        order_id: orderId,
      },
    }).then(() => {
      store.addNotification({
        title: 'Yes',
        message: 'Your order has been canceled! Please refresh the page.',
        type: 'success',
        insert: 'top',
        container: 'top-right',
        dismiss: {
          duration: 8000,
          onScreen: true,
        },
      });
    }).catch((error) => {
      // request failed
      console.log(error);
      store.addNotification({
        title: 'Error',
        message: 'Request failed. It is most likely that there is something wrong on our end. Please try again later.',
        type: 'danger',
        insert: 'bottom',
        container: 'top-right',
        dismiss: {
          duration: 8000,
          onScreen: true,
        },
      });
    });
    this.getOrders();
  }

  render() {
    const { data, box } = this.state;
    if (!data) {
      this.getOrders();
    }
    const filteredData = data;
    const rowsname = [];
    if (filteredData && filteredData.length > 0) {
      filteredData.sort((a, b) => b.order_id - a.order_id);
      for (let i = 0; i < filteredData.length; i += 1) {
        const order = filteredData[i];
        if (order.leg == null) {
          if (order.status === 'pending') {
            rowsname.push({
              id: i,
              order_id: order.id,
              symbol: order.symbol,
              option_symbol: order.option_symbol ? order.option_symbol : 'None',
              side: order.side,
              status: order.status,
              price: order.avg_fill_price,
              quantity: order.quantity,
              cancel: <Button style={{ backgroundColor: '#E53C18' }} onClick={() => { this.cancelOrders(order.id); }}>Cancel Order</Button>,
            });
          } else {
            rowsname.push({
              id: i,
              order_id: order.id,
              symbol: order.symbol,
              option_symbol: order.option_symbol ? order.option_symbol : 'None',
              side: order.side,
              status: order.status,
              price: order.avg_fill_price,
              quantity: order.quantity,
              cancel: '',
            });
          }
        } else if (order.status === 'pending') {
          let s = '';
          order.leg.forEach((entry) => {
            s += entry.option_symbol;
            s += ', ';
          });
          rowsname.push({
            id: i,
            order_id: order.id,
            symbol: order.symbol,
            option_symbol: `Multileg: ${s}`,
            side: order.side,
            status: order.status,
            price: order.avg_fill_price,
            quantity: order.quantity,
            cancel: <Button style={{ backgroundColor: '#E53C18' }} onClick={() => { this.cancelOrders(order.id); }}>Cancel Order</Button>,
          });
        } else {
          let s = '';
          order.leg.forEach((entry) => {
            s += entry.option_symbol;
            s += ', ';
          });
          rowsname.push({
            id: i,
            order_id: order.id,
            symbol: order.symbol,
            option_symbol: `Mullileg: ${s}`,
            side: order.side,
            status: order.status,
            price: order.avg_fill_price,
            quantity: order.quantity,
            cancel: '',
          });
        }
      }
    }
    const columnsname = [
      {
        label: 'Order id',
        field: 'order_id',
        sort: 'dsc',
        width: 150,
      },
      {
        label: 'Symbol',
        field: 'symbol',
        sort: 'asc',
        width: 270,
      },
      {
        label: 'Option Symbol',
        field: 'option_symbol',
        sort: 'asc',
        width: 270,
      },
      {
        label: 'Side',
        field: 'side',
        sort: 'asc',
        width: 150,
      },
      {
        label: 'Status',
        field: 'status',
        sort: 'asc',
        width: 200,
      },
      {
        label: 'Price',
        field: 'price',
        sort: 'asc',
        width: 100,
      },
      {
        label: 'Quantity',
        field: 'quantity',
        sort: 'asc',
        width: 150,
      },
      {
        label: 'Cancel',
        field: 'cancel',
        sort: 'asc',
        width: 100,
      },
    ];
    const mdbdata = {
      columns: columnsname,
      rows: rowsname,
    };
    return (
      <div>
        <Header isSearchable />
        <div style={box}>
          <Button onClick={this.getOrders}>Click to refresh Orders</Button>
          <MDBDataTable striped bordered small data={mdbdata} />
        </div>
      </div>
    );
  }
}

export default withRouter(ViewOrders);
