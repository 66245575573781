/* eslint-disable react/no-did-update-set-state */
import React from 'react';
import { withRouter } from 'react-router-dom';
import { store } from 'react-notifications-component';
import PropTypes from 'prop-types';
import Button from 'react-bootstrap/Button';

class Tradestock extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      curOption: 'Buy',
      curAmount: 1,
    };

    this.changeTradeOption = this.changeTradeOption.bind(this);
    this.changeTradeAmount = this.changeTradeAmount.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleSubmit(event) {
    event.preventDefault();
    const { curOption } = this.state;
    if (curOption === 'Buy') {
      store.addNotification({
        title: 'Error',
        message: 'Backend buy stock not available',
        type: 'danger',
        insert: 'top',
        container: 'top-right',
        dismiss: {
          duration: 3000,
          onScreen: true,
        },
      });
    } else if (curOption === 'Sell') {
      store.addNotification({
        title: 'Error',
        message: 'Backend sell stock not available',
        type: 'danger',
        insert: 'top',
        container: 'top-right',
        dismiss: {
          duration: 3000,
          onScreen: true,
        },
      });
    }
  }

  changeTradeOption(event) {
    this.setState({ curOption: event.target.value });
  }

  changeTradeAmount(event) {
    const value = event.target.value.replace(/\D/, '');
    if (value === '') {
      this.setState({ curAmount: '' });
    } else if (parseInt(value, 10) !== 0) {
      this.setState({ curAmount: value });
    }
  }

  render() {
    const {
      curOption, curAmount,
    } = this.state;
    const {
      ticker, unitPrice, cash,
    } = this.props;
    let buttonDisabled = curAmount;
    if (curAmount === '') {
      buttonDisabled = 'false';
    } else if (curAmount * unitPrice < cash) {
      buttonDisabled = '';
    }
    return (
      <form onSubmit={this.handleSubmit}>
        <div data-testid="tickerName">
          Ticker:
          {ticker}
        </div>
        <p />
        <div>
          <select data-testid="tradeOption" name="tradeOption" onChange={this.changeTradeOption}>
            <option value="Buy">Buy</option>
            <option value="Sell">Sell</option>
          </select>
        </div>
        <p />
        <div>
          Quantity:
          <input type="text" pattern="[1-9][0-9]*" data-testid="tradeQuantity" value={curAmount} onChange={this.changeTradeAmount} />
        </div>
        <p />
        <div data-testid="unitPrice">
          At: $
          { unitPrice }
          &nbsp;per share
        </div>
        <p />
        <div data-testid="totalPrice">
          For: $
          { curAmount * unitPrice }
        </div>
        <Button disabled={buttonDisabled} variant="primary" data-testid="tradestockbutton" type="submit">
          {curOption}
        </Button>
      </form>
    );
  }
}

Tradestock.propTypes = {
  ticker: PropTypes.string,
  unitPrice: PropTypes.number,
  cash: PropTypes.number,
};

Tradestock.defaultProps = {
  ticker: 'GOOGL',
  unitPrice: 2030,
  cash: 1000000,
};

export default withRouter(Tradestock);
