/* eslint-disable react/no-did-update-set-state */
import React from 'react';
import { store } from 'react-notifications-component';
import PropTypes from 'prop-types';
import Button from 'react-bootstrap/Button';
import axios from 'axios';
import Cookies from 'js-cookie';
import { withRouter } from 'react-router-dom';
import config from '../../../../config.json';

const serverAddress = config.SERVER_ADDR;
const tradeEndPoint = 'trade';

const promptErrorNotification = () => {
  store.addNotification({
    title: 'Error',
    message: 'Request failed. It is most likely that there is something wrong on our end. Please try again later.',
    type: 'danger',
    insert: 'bottom',
    container: 'top-right',
    dismiss: {
      duration: 8000,
      onScreen: true,
    },
  });
};

class TradeIronCondor extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      status: '',
    };
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleSubmit(event) {
    event.preventDefault();
    const {
      ticker, sides, contracts, quantities,
    } = this.props;
    const url = `${serverAddress}/api/${tradeEndPoint}/`;
    const endpoint = (Cookies.get('endpoint').localeCompare('developer_sandbox' === 0) ? 'brokerage_sandbox' : Cookies.get('endpoint'));
    axios.post(url,
      {},
      {
        params: {
          class: 'multileg',
          token: Cookies.get('token'),
          endpoint,
          account_id: Cookies.get('account'),
          symbol: ticker,
          duration: 'day',
          side: `[${sides.toString()}]`,
          quantity: `[${quantities.toString()}]`,
          type: 'market',
          option_symbol: `[${contracts.toString()}]`,
        },
      }, { crossDomain: true }).then((res) => {
      const status = JSON.parse(res.data);
      if (status.includes('ok')) {
        this.setState({ status: 'SUCCESSFULLY PLACED ORDER' });
        store.addNotification({
          title: 'Success',
          message: 'Placed order!',
          type: 'success',
          insert: 'top',
          container: 'top-right',
          dismiss: {
            duration: 3000,
            onScreen: true,
          },
        });
      } else {
        this.setState({ status });
        store.addNotification({
          title: 'Error',
          message: status,
          type: 'danger',
          insert: 'top',
          container: 'top-right',
          dismiss: {
            duration: 3000,
            onScreen: true,
          },
        });
      }
    }).catch(() => {
      // request failed
      promptErrorNotification();
    });
  }

  totBuyCost() {
    // Calculate the minimum amount of money needed to buy trade as
    // sum of (the quantities bought times their premiums).
    // The actual cost of the trade may be greater, to account for options risk.
    // That is decided by Tradier
    const { sides, quantities, premiums } = this.props;
    let totBuyPrice = 0;
    let i = 0;
    for (i; i < sides.length; i += 1) {
      if (sides[i].includes('buy')) {
        totBuyPrice += premiums[i] * quantities[i];
      }
    }
    return totBuyPrice;
  }

  render() {
    const {
      cash,
    } = this.props;
    const { status } = this.state;
    const buttonDisabled = this.totBuyCost() > cash;
    return (
      <div>
        <Button disabled={buttonDisabled} variant="primary" data-testid="tradeironcondorbutton" type="submit" onClick={this.handleSubmit}>
          Trade
        </Button>
        <div>
          { status }
        </div>
      </div>
    );
  }
}

TradeIronCondor.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  ticker: PropTypes.string,
  sides: PropTypes.arrayOf(PropTypes.string),
  contracts: PropTypes.arrayOf(PropTypes.string),
  quantities: PropTypes.arrayOf(PropTypes.number),
  premiums: PropTypes.arrayOf(PropTypes.number),
  cash: PropTypes.number,
};

TradeIronCondor.defaultProps = {
  ticker: 'GOOGL',
  sides: ['buy_to_open', 'sell_to_open', 'sell_to_open', 'buy_to_open'],
  contracts: ['GOOG210409C01180000', 'GOOG210409C01200000', 'GOOG210409C01300000', 'GOOG210409C01400000'],
  premiums: [1, 2, 3, 4],
  quantities: [1, 1, 1, 1],
  cash: 100000,
};

export default withRouter(TradeIronCondor);
