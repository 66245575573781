/* eslint-disable react/no-did-update-set-state */
import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import Header from '../Header/Header';
import Stockchart from '../Stockchart/Stockchart';
import IronCondorHandler from './IronCondor/IronCondorHandler';

class Tickerpage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      ticker: null,
      data: null,
    };
  }

  componentDidMount() {
    const { location } = this.props;
    this.setState({ ticker: location.state.ticker });
  }

  componentDidUpdate(previousProps) {
    const { location } = this.props;
    if (location.state.ticker !== previousProps.location.state.ticker) {
      this.setState({
        ticker: location.state.ticker,
      });
    }
  }

  render() {
    const { data, ticker } = this.state;
    if (data == null && ticker != null) {
      return (
        <div>
          <Header isSearchable />
          <div>
            <Stockchart ticker={ticker} />
            <IronCondorHandler ticker={ticker} />
            <p>
              Trade at your own risk!
              Condor for the Common Man is not liable for any damages
              that arise from trades on this site
              or trades based on data from this site.
            </p>
            <p>
              For other questions, comments, or concerns please email
              condorforthecommonman@gmail.com
            </p>
            <p>
              Powered by
              <a href="https://tradier.com" title="Powered by Tradier"> Tradier</a>
            </p>
          </div>
        </div>
      );
    }
    return (
      <div>
        <Header isSearchable />
        <div>
          {`Loaded ${ticker}`}
        </div>
      </div>
    );
  }
}

Tickerpage.propTypes = {
  location: PropTypes.shape({
    state: PropTypes.shape({
      ticker: PropTypes.string.isRequired,
      // eslint-disable-next-line react/forbid-prop-types
      data: PropTypes.any,
    }).isRequired,
  }).isRequired,
};

export default withRouter(Tickerpage);
