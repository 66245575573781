import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import 'bootstrap/dist/css/bootstrap.min.css';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { store } from 'react-notifications-component';
import './Login.css';
import Header from '../Header/Header';
import { authLogin } from '../../services/auth';

class LoginPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      token: '',
      isSandbox: true,
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleCheckboxChange = this.handleCheckboxChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleAuthorization = this.handleAuthorization.bind(this);
    this.validateForm = this.validateForm.bind(this);
  }

  handleChange(event) {
    const nam = event.target.name;
    const val = event.target.value;
    this.setState({ [nam]: val });
  }

  handleCheckboxChange(event) {
    const nam = event.target.name;
    const val = event.target.checked;
    this.setState({ [nam]: val });
  }

  handleSubmit(event) {
    event.preventDefault();
    // Fetch from backend and line 51 executes only if token is valid
    this.handleAuthorization(event);
  }

  handleAuthorization() {
    const { history } = this.props;
    const { token, isSandbox } = this.state;
    authLogin({ token, isSandbox }).then((res) => {
      if (res) history.push('/profile');
      else {
        store.addNotification({
          title: 'Error',
          message: 'Failed to log in',
          type: 'danger',
          insert: 'top',
          container: 'top-right',
          dismiss: {
            duration: 3000,
            onScreen: true,
          },
        });
      }
    });
  }

  validateForm() {
    const { token } = this.state;
    return token.length > 0;
  }

  render() {
    const {
      isSandbox,
      token,
    } = this.state;
    return (
      <>
        <Header isSearchable={false} />
        <div className="Login">
          <Form onSubmit={this.handleSubmit}>
            <Form.Group size="lg" controlId="email">
              <Form.Label>Tradier API Token</Form.Label>
              <Form.Control
                type="text"
                name="token"
                data-testid="token-text"
                onChange={this.handleChange}
                value={token}
                placeholder="Enter Tradier API Token"
              />
            </Form.Group>
            <Form.Group size="lg" controlId="formBasicCheckbox">
              <Form.Label>Password</Form.Label>
              <Form.Check
                className="text-muted"
                name="isSandbox"
                data-testid="isSandbox-checkbox"
                type="checkbox"
                label="Sandbox Account (If unchecked, we will assume this is a brokerage token)"
                checked={isSandbox}
                onChange={this.handleCheckboxChange}
              />
            </Form.Group>
            <Button block size="lg" type="submit" data-testid="login-btn" disabled={!this.validateForm()}>
              Login
            </Button>
          </Form>
        </div>
      </>
    );
  }
}

LoginPage.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
};

export default withRouter(LoginPage);
