import React from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Redirect, withRouter } from 'react-router-dom';
import { store } from 'react-notifications-component';
import PropTypes from 'prop-types';
import axios from 'axios';
import config from '../../config.json';
import 'bootstrap/dist/css/bootstrap.css';

const serverAddress = config.SERVER_ADDR;
const tickerValidateEndPoint = 'tickers';

class Searchbar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      ticker: '',
    };

    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(event) {
    this.setState({ ticker: event.target.value.toUpperCase() });
  }

  handleSubmit(event) {
    const { ticker } = this.state;
    const { history } = this.props;
    const url = `${serverAddress}/api/${tickerValidateEndPoint}/`;
    event.preventDefault();
    if (ticker !== '') {
      axios.get(url, {
        params: { ticker_symbol: ticker },
      }, { crossDomain: true }).then((res) => {
        const tickerList = JSON.parse(res.data);
        if (tickerList.includes(ticker)) {
          history.push({
            pathname: 'ticker',
            search: `?symbol=${ticker}`,
            state: { ticker },
          });
        } else {
          // ticker isn't valid
          this.setState({ ticker: '' });
          store.addNotification({
            title: 'Warning',
            message: 'Invalid ticker',
            type: 'warning',
            insert: 'bottom',
            container: 'top-right',
            dismiss: {
              duration: 3000,
              onScreen: true,
            },
          });
        }
      }).catch(() => {
        // request failed
        store.addNotification({
          title: 'Error',
          message: 'Request failed. It is most likely that there is something wrong on our end. Please try again later.',
          type: 'danger',
          insert: 'bottom',
          container: 'top-right',
          dismiss: {
            duration: 8000,
            onScreen: true,
          },
        });
      });
    }
  }

  render() {
    const { selected, ticker } = this.state;
    if (!selected) {
      return (
        <div>
          <Form onSubmit={this.handleSubmit}>
            <Container>
              <Row>
                <Col sm={8}>
                  <Form.Control
                    type="text"
                    id="ticker-search-box"
                    onChange={this.handleChange}
                    value={ticker}
                    placeholder="Enter ticker symbol"
                  />
                </Col>
                <Col sm={4}>
                  <Button variant="primary" id="ticker-search-btn" type="submit">Search</Button>
                </Col>
              </Row>
            </Container>
          </Form>
        </div>
      );
    }
    return (
      <Redirect
        to={{
          pathname: '/ticker',
          state: { ticker },
        }}
      />
    );
  }
}

Searchbar.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
};

export default withRouter(Searchbar);
