import Cookies from 'js-cookie'; // eslint-disable-line
import axios from 'axios';
import config from '../config.json';

export const checkIsAuthenticated = () => !!Cookies.get('token');

export const authLogin = (credentials) => {
  const endpoint = credentials.isSandbox ? 'developer_sandbox' : 'brokerage';
  const urlToFetch = `${config.SERVER_ADDR}/api/login/?token=${credentials.token}&endpoint=${endpoint}`;
  return axios.get(urlToFetch).then((res) => {
    if (res.status === 200) {
      Cookies.set('token', credentials.token);
      Cookies.set('endpoint', endpoint);
      return true;
    }
    return false;
  }, () => false);
};

export const authLogout = () => {
  Cookies.remove('token');
  Cookies.remove('account');
  window.location.href = '/';
};
