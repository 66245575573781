import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import 'moment-timezone';
import Select from 'react-select';
import { FaCalendar } from 'react-icons/fa';

const toOption = (dateopt) => (
  { value: dateopt, label: moment(dateopt).format('YYYY-MM-DD') }
);

class DatePickerTextBox extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      availableDates: [],
    };
    this.setupCalendarValues = this.setupCalendarValues.bind(this);
    this.handleKeyPress = this.handleKeyPress.bind(this);
    this.handleSelectChange = this.handleSelectChange.bind(this);
    this.expirationDateOptions = this.expirationDateOptions.bind(this);
  }

  componentDidMount() {
    this.setupCalendarValues();
  }

  handleKeyPress(e) {
    const { charCode } = e;
    const { handleSelect } = this.props;
    // Enter || Space
    if (charCode === 13 || charCode === 32) {
      handleSelect();
    }
  }

  handleSelectChange(e) {
    const { handleSelectDate } = this.props;
    handleSelectDate(e.value);
  }

  setupCalendarValues() {
    const {
      availableExpirationDates, startDate, date, discreteHandleSelectDate,
    } = this.props;
    const availableDates = availableExpirationDates.filter((dateC) => !(dateC < startDate));
    this.setState({ availableDates });
    discreteHandleSelectDate((availableDates.includes(date)) ? date : availableDates[0]);
  }

  expirationDateOptions() {
    const { availableDates } = this.state;
    const avails = availableDates.map(toOption);
    const { date } = this.props;
    return (
      <Select
        value={toOption(date)}
        options={avails}
        onChange={(e) => this.handleSelectChange(e)}
      />
    );
  }

  render() {
    const { handleSelect } = this.props;
    return (
      <div className="datepicker">
        Select Expiration Dates:
        <div
          className="selected-date"
          onClick={handleSelect}
          onKeyPress={this.handleKeyPress}
          tabIndex="0"
          role="button"
          aria-label="Datepicker"
        >
          <FaCalendar className="icon" />
        </div>
        <div className="selected-date">
          { this.expirationDateOptions() }
        </div>
      </div>
    );
  }
}

DatePickerTextBox.propTypes = {
  availableExpirationDates: PropTypes.arrayOf(PropTypes.instanceOf(Date)).isRequired,
  date: PropTypes.instanceOf(Date).isRequired,
  startDate: PropTypes.instanceOf(Date).isRequired,
  handleSelect: PropTypes.func.isRequired,
  handleSelectDate: PropTypes.func.isRequired,
  discreteHandleSelectDate: PropTypes.func.isRequired,
};

export default DatePickerTextBox;
