import React from 'react';
import { withRouter } from 'react-router-dom';
import Searchbar from '../Searchbar/Searchbar';
import './Homepage.css';
import Header from '../Header/Header';

class Homepage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div>
        <Header isSearchable={false} />
        <div id="title">
          Condor for the Common Man
        </div>
        <div id="search-bar">
          <Searchbar />
        </div>
      </div>
    );
  }
}

export default withRouter(Homepage);
