import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import 'moment-timezone';
import DatePickerTextBox from './DatePickerTextBox';
import RangeCalendar from './RangeCalendar';
import './DatePicker.css';

const timeIndicator = 'T23:59:59';
const toLocalDate = (dateStr) => (new Date(moment(dateStr + timeIndicator).format('YYYY-MM-DD HH:mm:ss')));

class DatePicker extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showCalendar: false,
      date: new Date(Date.now()),
    };
    this.setShowCalendar = this.setShowCalendar.bind(this);
    this.setDate = this.setDate.bind(this);
    this.toggleCalendar = this.toggleCalendar.bind(this);
    this.handleSelectDate = this.handleSelectDate.bind(this);
    this.discreteHandleSelectDate = this.discreteHandleSelectDate.bind(this);
  }

  handleSelectDate(date) {
    let dateValid = date;
    if (typeof dateValid !== 'object') {
      dateValid = toLocalDate(date);
    }
    this.setDate(dateValid);
    this.setShowCalendar(false);
    const { updateCallback } = this.props;
    updateCallback(dateValid);
  }

  setShowCalendar(value) {
    this.setState({ showCalendar: value });
  }

  setDate(value) {
    this.setState({ date: value });
  }

  toggleCalendar() {
    const { showCalendar } = this.state;
    this.setShowCalendar(!showCalendar);
  }

  discreteHandleSelectDate(date) {
    let dateValid = date;
    if (typeof dateValid !== 'object') {
      dateValid = toLocalDate(date);
    }
    this.setDate(dateValid);
    const { updateCallback } = this.props;
    updateCallback(dateValid);
  }

  render() {
    const { startDate, availableExpirationDates } = this.props;
    const { showCalendar, date } = this.state;
    return (
      <div className="DatepickerContainer">
        <div style={{ display: 'inline-block', width: '200px' }}>
          <DatePickerTextBox
            availableExpirationDates={availableExpirationDates}
            date={date}
            startDate={startDate}
            handleSelect={this.toggleCalendar}
            handleSelectDate={this.handleSelectDate}
            discreteHandleSelectDate={this.discreteHandleSelectDate}
          />
        </div>
        {showCalendar && (
          <div className="calendar">
            <RangeCalendar
              availableExpirationDates={availableExpirationDates}
              curDate={date}
              startDate={startDate}
              handleSelectDate={this.handleSelectDate}
              discreteHandleSelectDate={this.discreteHandleSelectDate}
            />
          </div>
        )}
      </div>
    );
  }
}

DatePicker.propTypes = {
  availableExpirationDates: PropTypes.arrayOf(PropTypes.instanceOf(Date)).isRequired,
  startDate: PropTypes.instanceOf(Date).isRequired,
  updateCallback: PropTypes.func.isRequired,
};

export default DatePicker;
