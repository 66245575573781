import React from 'react';
import { withRouter, Link } from 'react-router-dom';
import Dropdown from 'react-dropdown';
import DataGrid from 'react-data-grid';
import 'react-dropdown/style.css';
import axios from 'axios';
import Cookies from 'js-cookie';
import { Button } from 'react-bootstrap';
import Header from '../Header/Header';
import '../../App.css';
import config from '../../config.json';
import bindMethods from '../../services/componentutil';

const serverAddress = config.SERVER_ADDR;

function convertToDollars(value) {
  const valueNum = Number(value).toFixed(2);
  let returnValue = '';
  if (valueNum < 0) {
    returnValue = `${String(valueNum).charAt(0)}$${String(valueNum).slice(1, String(valueNum).length)}`;
  } else {
    returnValue = `$${String(valueNum)}`;
  }
  return returnValue;
}

const tryGetValueOrNA = (func) => {
  try {
    const ans = func();
    if (ans === null || ans === undefined) {
      return 'N/A';
    }
    return convertToDollars(ans);
  } catch (e) {
    return 'N/A';
  }
};

const UPDATE_EVERY_N_SECONDS = 10;

class Profilepage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      accountNumber: null,
      accountData: null,
      data: null,
      error: false,
      lastUpdated: 0,
    };
    this.update_interval = null;
    bindMethods(this, [
      'onSelect',
      'updateData',
      'updateDataSuccess',
      'setDefaultAccount',
      'setCurrentAccount',
      'getUpdateDateString',
    ]);
  }

  componentDidMount() {
    this.updateData();
    this.update_interval = setInterval(this.updateData, UPDATE_EVERY_N_SECONDS * 1000);
  }

  componentWillUnmount() {
    if (this.update_interval != null) clearInterval(this.update_interval);
  }

  onSelect(selected) {
    this.setCurrentAccount(selected.value);
  }

  getUpdateDateString() {
    const { lastUpdated } = this.state;
    if (!lastUpdated) return '';
    return new Date(lastUpdated).toGMTString();
  }

  setDefaultAccount() {
    const { data } = this.state;
    const hasAccounts = data.account.length > 0;
    if (hasAccounts) this.setCurrentAccount(data.account[0].account_number);
  }

  setCurrentAccount(accNum) {
    // Sets the current account displayed in the profile page
    const { data } = this.state;
    let accData;
    data.account.forEach((dat) => {
      if (dat.account_number === accNum) accData = dat;
    });
    if (accData) {
      this.setState({ accountNumber: accNum, accountData: accData });
      // Set the account number used for trading as a cookie
      Cookies.set('account', accNum);
    } else {
      this.setDefaultAccount();
    }
  }

  getOptionBuyingPower() {
    const { accountData } = this.state;
    let optionBuyingPower = tryGetValueOrNA(() => accountData.balances.margin.option_buying_power);
    if (optionBuyingPower.localeCompare('N/A') === 0) {
      optionBuyingPower = tryGetValueOrNA(() => accountData.balances.pdt.option_buying_power);
    }
    return optionBuyingPower;
  }

  getStockBuyingPower() {
    const { accountData } = this.state;
    let stockBuyingPower = tryGetValueOrNA(() => accountData.balances.margin.stock_buying_power);
    if (stockBuyingPower.localeCompare('N/A') === 0) {
      stockBuyingPower = tryGetValueOrNA(() => accountData.balances.pdt.stock_buying_power);
    }
    return stockBuyingPower;
  }

  getTotalCash() {
    const { accountData } = this.state;
    return tryGetValueOrNA(() => accountData.balances.total_cash);
  }

  getAvailableCash() {
    const { accountData } = this.state;
    return tryGetValueOrNA(() => accountData.balances.cash.cash_available);
  }

  updateDataSuccess(res) {
    this.setState({ lastUpdated: Date.now() });
    if (res.status !== 200) {
      this.setState({ error: true });
      return;
    }
    this.setState({ data: res.data });
    const { accountNumber } = this.state;
    this.setCurrentAccount(accountNumber);
  }

  updateData() {
    axios.request({
      method: 'GET',
      url: `${serverAddress}/api/profile/?token=${Cookies.get('token')}&endpoint=${Cookies.get('endpoint')}`,
    }).then(this.updateDataSuccess).catch(() => {
      this.setState({ error: true });
    });
  }

  render() {
    const {
      accountNumber,
      accountData,
      data,
      error,
    } = this.state;
    if (error) {
      return (
        <div>
          Error: Failed to load profile
        </div>
      );
    }
    if (data == null) {
      return (
        <div>
          Loading...
        </div>
      );
    }
    const accountNums = data.account.map((acc) => acc.account_number);
    const userinfo = (
      <div>
        <Header isSearchable />
        <h1>Profile Page</h1>
        <p>
          <b>Last Updated: </b>
          { this.getUpdateDateString() }
        </p>
        <p>
          <b>User ID: </b>
          { data.id }
        </p>
        <p>
          <b>User Name: </b>
          { data.name }
        </p>
      </div>
    );
    if (data.account.length === 0 || !accountNumber) {
      return (
        <div>
          { userinfo }
          You have no accounts!
        </div>
      );
    }

    const rows = [];
    if (accountData.positions) {
      for (let i = 0; i < accountData.positions.length; i += 1) {
        const pos = accountData.positions[i];
        rows.push({
          id: i,
          title: pos.symbol,
          quantity: pos.quantity,
          cost_basis: convertToDollars(pos.cost_basis),
        });
      }
    }
    const columns = [
      { key: 'title', name: 'Symbol' },
      { key: 'quantity', name: 'Quantity' },
      { key: 'cost_basis', name: 'Cost basis' },
    ];

    return (
      <div>
        { userinfo }
        <p>
          <b>Selected Account Number: </b>
          <div style={{ display: 'inline-block', width: '200px' }}>
            <Dropdown options={accountNums} onChange={this.onSelect} value={accountNums[0]} placeholder="Select an option" />
          </div>
        </p>
        <p>
          <b>Account Type: </b>
          { accountData.type }
        </p>
        <p>
          <b>Total Cash: </b>
          { this.getTotalCash() }
        </p>
        <p>
          <b>Cash Available: </b>
          { this.getAvailableCash() }
        </p>
        <p>
          <b>Option Buying Power: </b>
          { this.getOptionBuyingPower() }
        </p>
        <p>
          <b>Stock Buying Power: </b>
          { this.getStockBuyingPower() }
        </p>
        <DataGrid rows={rows} columns={columns} />
        <p>
          <Link to="/orders">
            <Button>
              View Orders
            </Button>
          </Link>
        </p>
      </div>
    );
  }
}

export default withRouter(Profilepage);
