import React from 'react';
import { store } from 'react-notifications-component';
import NumericInput from 'react-numeric-input';
import PropTypes from 'prop-types';
import Select from 'react-select';
import axios from 'axios';
import moment from 'moment';
import 'moment-timezone';
import IronCondor from './IronCondor';
import TradeIronCondor from './TradeIronCondor/TradeIronCondor';
import config from '../../../config.json';
import DatePicker from '../../Calendar/DatePicker';
import { checkIsAuthenticated } from '../../../services/auth';

const serverAddress = config.SERVER_ADDR;
const expirationDatesEndPoint = 'expirations';
const optionChainEndPoint = 'option';

const toOption = (op) => ({ value: op, label: op });

const timeIndicator = 'T23:59:59';
const toLocalDate = (dateStr) => (new Date(moment(dateStr + timeIndicator).format('YYYY-MM-DD HH:mm:ss')));
const dateToString = (dateObj) => (moment(dateObj).format('YYYY-MM-DD'));

const promptErrorNotification = () => {
  store.addNotification({
    title: 'Error',
    message: 'Request failed. It is most likely that there is something wrong on our end. Please try again later.',
    type: 'danger',
    insert: 'bottom',
    container: 'top-right',
    dismiss: {
      duration: 8000,
      onScreen: true,
    },
  });
};

class IronCondorHandler extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      expirationDate: null,
      availableExpirationDates: null,
      strikePrices: null,
      availableStrikePrices: null,
      optionCredit: null, // {strikePrice1: [putCredit1, callCredit1]}
      currStockPrice: null,
      shortIronCondor: true,
      multiplier: 100,
    };

    this.handleUpdate = this.handleUpdate.bind(this);
    this.handleUpdateDate = this.handleUpdateDate.bind(this);
    this.getExpirationDates = this.getExpirationDates.bind(this);
    this.getCredit = this.getCredit.bind(this);
    this.getOptionChain = this.getOptionChain.bind(this);
    this.changeMultiplier = this.changeMultiplier.bind(this);
  }

  componentDidMount() {
    this.getExpirationDates();
  }

  componentDidUpdate(prevProps, prevState) {
    const { ticker } = this.props;
    const { expirationDate } = this.state;
    if (prevProps.ticker !== ticker) {
      // ticker got updated
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ expirationDate: null });
      this.getExpirationDates();
    } else if (expirationDate !== null
      && JSON.stringify(prevState.expirationDate) !== JSON.stringify(expirationDate)) {
      this.getOptionChain();
    }
  }

  handleTradeSubmission() {
    const { tradeCallback } = this.props;
    tradeCallback();
  }

  handleUpdateDate(val) {
    this.setState({ expirationDate: dateToString(val) });
  }

  handleUpdate(index, val) {
    const { strikePrices } = this.state;
    const newStrikePrices = [...strikePrices];
    newStrikePrices[index] = val;
    this.setState({ strikePrices: newStrikePrices });
  }

  handleSelectChange(index, dat) {
    this.handleUpdate(index, dat.value);
  }

  getCredit() {
    const { strikePrices, optionCredit, shortIronCondor } = this.state;
    const toReturn = ['', '']; // The first index is the premiums and the second index is the contracts
    const premuims = [0, 0, 0, 0];
    const contracts = ['', '', '', ''];
    let i = 0;
    if (shortIronCondor) {
      // put put call call
      const putCallIdx = [1, 1, 0, 0];
      for (i = 0; i < 4; i += 1) {
        premuims[i] = optionCredit[strikePrices[i]][putCallIdx[i]];
        contracts[i] = optionCredit[strikePrices[i]][putCallIdx[i] + 2];
      }
    } else {
      // call call put put
      const putCallIdx = [0, 0, 1, 1];
      for (i = 0; i < 4; i += 1) {
        premuims[i] = optionCredit[strikePrices[i]][putCallIdx[i]];
        contracts[i] = optionCredit[strikePrices[i]][putCallIdx[i] + 2];
      }
    }
    toReturn[0] = premuims;
    toReturn[1] = contracts;
    return toReturn;
  }

  getExpirationDates() {
    const { ticker } = this.props;
    const url = `${serverAddress}/api/${expirationDatesEndPoint}/`;
    axios.get(url, {
      params: { ticker_symbol: ticker },
    }, { crossDomain: true }).then((res) => {
      const results = JSON.parse(res.data);
      // select the first date as default date
      const availableExpirationDates = results.map(toLocalDate);
      const expirationDate = dateToString(availableExpirationDates[0]);
      this.setState({
        availableExpirationDates,
        expirationDate,
      });
    }).catch(() => {
      // request failed
      promptErrorNotification();
    });
  }

  getOptionChain() {
    const { ticker } = this.props;
    const { expirationDate } = this.state;
    const url = `${serverAddress}/api/${optionChainEndPoint}/`;
    axios.get(url, {
      params: { ticker_symbol: ticker, expiration_date: expirationDate },
    }, { crossDomain: true }).then((res) => {
      const optionChain = JSON.parse(res.data);
      const {
        defaultStrikes,
        availableStrikePrices,
        currStockPrice,
        credits,
      } = optionChain;
      const creditsNumberKey = {};
      // eslint-disable-next-line no-restricted-syntax
      for (const [key] of Object.entries(credits)) {
        creditsNumberKey[parseFloat(key)] = credits[key];
      }
      this.setState({
        strikePrices: defaultStrikes,
        availableStrikePrices,
        currStockPrice,
        optionCredit: creditsNumberKey,
      });
    }).catch(() => {
      // request failed
      promptErrorNotification();
    });
  }

  changeMultiplier(e) {
    if (e) {
      // eslint-disable-next-line
      console.log(e.toString());
    }
    if (e > 0) {
      this.setState({ multiplier: 100 * e });
    } else {
      // If e is not null, then it is an integer
      // thus, this line prevents a value of e < 1
      this.setState({ multiplier: 100 });
    }
  }

  strikeSelector(index) {
    const { strikePrices, availableStrikePrices } = this.state;
    const avails = availableStrikePrices.map(toOption).map((op) => {
      if ((index > 0 && op.value <= strikePrices[index - 1])
          || (index < 3 && op.value >= strikePrices[index + 1])) {
        // eslint-disable-next-line no-param-reassign
        op.isDisabled = true;
      }
      return op;
    });
    return (
      <div style={{ display: 'inline-block', width: '200px' }}>
        <Select
          value={toOption(strikePrices[index])}
          options={avails}
          onChange={(dat) => this.handleSelectChange(index, dat)}
          style={{ width: '300px' }}
        />
      </div>
    );
  }

  render() {
    const {
      strikePrices,
      availableStrikePrices,
      currStockPrice,
      shortIronCondor,
      multiplier,
      availableExpirationDates,
    } = this.state;
    const { ticker } = this.props;
    if (strikePrices) {
      if (checkIsAuthenticated()) {
        return (
          <div>
            Quantity:
            <NumericInput
              step={1}
              value={Math.floor(multiplier / 100)}
              onChange={this.changeMultiplier}
            />
            <TradeIronCondor
              ticker={ticker}
              sides={['buy_to_open', 'sell_to_open', 'sell_to_open', 'buy_to_open']}
              contracts={this.getCredit()[1]}
              premiums={this.getCredit()[0]}
              quantities={[Math.floor(multiplier / 100),
                Math.floor(multiplier / 100),
                Math.floor(multiplier / 100),
                Math.floor(multiplier / 100)]}
              // TODO: Make dynamic
              cash={1000000000}
            />
            Strike Price 1:
            {' '}
            { this.strikeSelector(0) }
            Strike Price 2:
            {' '}
            { this.strikeSelector(1) }
            Strike Price 3:
            {' '}
            { this.strikeSelector(2) }
            Strike Price 4:
            {' '}
            { this.strikeSelector(3) }
            <DatePicker
              availableExpirationDates={availableExpirationDates}
              startDate={new Date(Date.now())}
              updateCallback={this.handleUpdateDate}
            />
            <IronCondor
              credits={this.getCredit()[0]}
              currStockPrice={currStockPrice}
              strikePrices={strikePrices}
              multiplier={multiplier}
              strikes={availableStrikePrices}
              updateCallback={this.handleUpdate}
              shortIronCondor={shortIronCondor}
            />
          </div>
        );
      }
      return (
        <div>
          Quantity:
          <NumericInput
            step={1}
            value={Math.floor(multiplier / 100)}
            onChange={this.changeMultiplier}
          />
          <br />
          Strike Price 1:
          {' '}
          { this.strikeSelector(0) }
          Strike Price 2:
          {' '}
          { this.strikeSelector(1) }
          Strike Price 3:
          {' '}
          { this.strikeSelector(2) }
          Strike Price 4:
          {' '}
          { this.strikeSelector(3) }
          <DatePicker
            availableExpirationDates={availableExpirationDates}
            startDate={new Date(Date.now())}
            updateCallback={this.handleUpdateDate}
          />
          <IronCondor
            credits={this.getCredit()[0]}
            currStockPrice={currStockPrice}
            strikePrices={strikePrices}
            multiplier={multiplier}
            strikes={availableStrikePrices}
            updateCallback={this.handleUpdate}
            shortIronCondor={shortIronCondor}
          />
        </div>
      );
    }
    return (
      <div>
        Loading
      </div>
    );
  }
}

IronCondorHandler.propTypes = {
  tradeCallback: PropTypes.func,
  ticker: PropTypes.string.isRequired,
};

IronCondorHandler.defaultProps = {
  tradeCallback: () => {},
};

export default IronCondorHandler;
