import React from 'react';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import Button from 'react-bootstrap/Button';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import Searchbar from '../Searchbar/Searchbar';
import 'bootstrap/dist/css/bootstrap.css';
import { checkIsAuthenticated, authLogout } from '../../services/auth';
import './Header.css';

class Header extends React.Component {
  constructor(props) {
    super(props);
    const { isSearchable } = this.props;
    this.state = {
      isSearchable,
      isLoggedIn: checkIsAuthenticated(),
    };
    this.handleLogin = this.handleLogin.bind(this);
    this.handleLogout = this.handleLogout.bind(this);
  }

  handleLogin() {
    // Handle login
    this.goToLoginPage();
  }

  handleLogout() {
    // Handle logout
    authLogout();
    this.setState({ isLoggedIn: false });
    this.goToLoginPage();
  }

  goToLoginPage() {
    const { history } = this.props;
    history.push('/login');
  }

  render() {
    const loginButton = <Button variant="success" id="user-action-btn" onClick={this.handleLogin}>Login</Button>;
    const logoutButton = <Button variant="danger" id="user-action-btn" onClick={this.handleLogout}>Logout</Button>;
    const { isLoggedIn, isSearchable } = this.state;

    let shownButton;
    if (isLoggedIn) {
      shownButton = logoutButton;
    } else {
      shownButton = loginButton;
    }
    return (
      <div>
        <Navbar bg="dark" variant="dark" sticky="top">
          <Navbar.Brand href="/">Condor for the Common Man</Navbar.Brand>
          <Nav className="mr-auto">
            <Nav.Link href="/">Home</Nav.Link>
            {isLoggedIn && <Nav.Link data-testid="redirect-profile-btn" href="/profile">Profile</Nav.Link>}
            {isLoggedIn && <Nav.Link href="/orders">Orders</Nav.Link>}
            <Nav.Link href="https://github.com/jtisaac/condor-for-the-common-man">Github</Nav.Link>
          </Nav>
          {isSearchable && <Searchbar />}
          {shownButton}
        </Navbar>
      </div>
    );
  }
}

Header.propTypes = {
  isSearchable: PropTypes.bool.isRequired,
  // need to made .isRequired after login is implemented
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
};

export default withRouter(Header);
